import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Wrapper, SectionH2, SectionParag, Section3Col, Columns, SectionH3, SectionHeaderOnlyWhite } from "../styles/style.js";

const BigButton = styled(Link)`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
`;

const SmallButton = styled(BigButton)`
  padding: 10px 15px;
  max-width: 200px;
`;

const FleetPage = () => (
  <Layout>
    <Seo
      title="Our Fleet"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.9",
          height: 500,
        }}
        alt="limo service fleet"
        src="../img/header-fleet-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-5xl md:text-7xl font-extrabold text-center">Our Fleet</div>
        </div>
      </div>
    </div>

    <SectionHeaderOnlyWhite>
      <Wrapper>
        <SectionH2>Choose Your Ride</SectionH2>
      </Wrapper>
    </SectionHeaderOnlyWhite>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-lincoln-sedan.png" alt="lincoln sedan" />
        <SectionH3>Lincoln Sedan</SectionH3>
        <SectionParag>1 to 3 Passengers</SectionParag>
        <SmallButton to="/fleet-sedan">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-suburban.png" alt="black suburban" />
        <SectionH3>Suburban</SectionH3>
        <SectionParag>3 to 6 Passengers</SectionParag>
        <SmallButton to="/fleet-suburban">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-lincoln-limo.png" alt="lincoln limo" />
        <SectionH3>Lincoln Limo</SectionH3>
        <SectionParag>4 to 9 Passengers</SectionParag>
        <SmallButton to="/fleet-limo">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-van.png" alt="passenger van" />
        <SectionH3>Van</SectionH3>
        <SectionParag>12 Passengers</SectionParag>
        <SmallButton to="/fleet-van">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-sprinter.png" alt="sprinter van" />
        <SectionH3>Sprinter Limo or Van</SectionH3>
        <SectionParag>12 Passengers</SectionParag>
        <SmallButton to="/fleet-sprinter">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-white-hummer-limo.png" alt="hummer limo" />
        <SectionH3>Hummer Limo</SectionH3>
        <SectionParag>18 Passengers</SectionParag>
        <SmallButton to="/fleet-hummer-limo">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-champagne-escalade.png" alt="escalade limo" />
        <SectionH3>Escalade Limo</SectionH3>
        <SectionParag>18 Passengers</SectionParag>
        <SmallButton to="/fleet-escalade">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-party-bus.png" alt="party bus" />
        <SectionH3>Party Bus</SectionH3>
        <SectionParag>10 to 30 Passengers</SectionParag>
        <SmallButton to="/fleet-party-bus">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default FleetPage